import React from 'react'
import { Link } from 'gatsby'

const LayoutHeader = ({ type }) => {
  return (
    <div className="auth-page-header">
      <Link to="/" className="logo">
        <img src="/quick.png" alt="Quick API" height="35" />
        <span style={{ color: '#000' }}>Quick API</span>
      </Link>
      {/* {type !== 'setup' && (
        <Link to={type === 'login' ? '/signup' : '/login'} className="nav-link">
          {type === 'login'
            ? 'Don’t have an account?'
            : 'I already have an account —'}{' '}
          <b>
            <u>{type === 'login' ? 'Create new account' : 'Sign in'}</u>
          </b>
        </Link>
      )} */}
    </div>
  )
}

export default LayoutHeader
