import React, { useState } from 'react'
import { Modal, Form, message } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import { RiSendPlaneFill } from 'react-icons/ri'
import { Firebase } from '../config/firebase'
import { Button, Input } from '@geist-ui/core'

const ForgotPwd = () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const showModal = () => {
    setVisible(true)
  }
  const handleCancel = () => {
    setVisible(false)
  }

  const handleResetPwd = values => {
    setLoading(true)
    const { email } = values
    Firebase.auth()
      .sendPasswordResetEmail(email)
      .then(function (re) {
        setLoading(false)
        setVisible(false)
        form.resetFields()
        message.success(
          'We sent you email to make new password. Also, check your spam folder.',
        )
      })
      .catch(function (error) {
        setLoading(false)
        message.success(error.message)
      })
  }

  return (
    <>
      <p onClick={showModal} style={{ cursor: 'pointer' }}>
        Forgot the password? —{' '}
        <u>
          <b>Reset now</b>
        </u>
      </p>
      <Modal
        title="Reset your password"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={handleResetPwd}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input registered email!',
              },
            ]}
            extra="You will recieved an email to reset your QuickAPI password."
          >
            <Input width="100%" placeholder="Email Address" />
          </Form.Item>

          <Form.Item>
            <Button
              type="secondary"
              icon={<RiSendPlaneFill />}
              loading={loading}
              htmlType="submit"
              className="login-form-button"
            >
              Send Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ForgotPwd
